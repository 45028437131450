import * as React from 'react';
import { graphql, Link } from 'gatsby';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import FancyTitle from '../components/fancy-title';
import InfoBox from '../components/info-box';

import Testimonials from '../components/testimonials';
import References from '../components/references';

import ScrollSlider from '../components/scroll-slider';
import ImagePane from '../components/image-pane';

function ReferenceTemplate({ data }) {
  const reference = data.markdownRemark.frontmatter;
  const rightPane = (
    <GatsbyImage
      image={reference.description_image.childImageSharp.gatsbyImageData}
      alt={reference.title}
      className="w-full h-full"
      objectFit="contain"
    />
  );
  const references = data.allMarkdownRemark?.nodes?.filter((item) => reference?.similar?.includes(item.frontmatter.link.replace('/references/', '')));

  const testimonials = React.useMemo(() => {
    if (!reference.testimonials) return null;
    return reference.testimonials.map((item) => ({ node: item }));
  }, [reference]);

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({
          pageTitle: reference.meta_title
            ? reference.meta_title : reference.title,
          fullWidth: true,
        });
        if (!reference) {
          return null;
        }
        return (
          <>
            <Seo
              title={reference.meta_title ? reference.meta_title : reference.title}
              description={reference?.meta_description.replace(/(<([^>]+)>)/ig, '')}
            />
            <InfoBox data={reference} />
            <div className="px-4 lg:px-16 pt-20 lg:pt-36">
              <ImagePane
                image={rightPane}
                title={(
                  <FancyTitle
                    text={reference.title}
                    tag="h2"
                    className="mb-8 xl:mb-12"
                  />
                )}
                desktopImagePosition="right"
                desktopTitleVerticalPosition="center"
                content={(
                  <>
                    <p dangerouslySetInnerHTML={{ __html: reference.description }} className="py-8 lg:py-0" />
                    <Link
                      to={reference.url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-latori-green font-monospace font-bold uppercase text-left text-xl mt-4"
                    >
                      Zur Website
                    </Link>
                  </>
                )}
              />

              <div className="my-24 lg:my-60">
                <FancyTitle text="Herausforderung / Ziel" tag="h2" />
                <ul className="lg:columns-2 lg:gap-16 list-disc list-outside ml-6 lg:ml-4 marker:text-latori-green marker:text-2xl lg:marker:text-3xl">
                  {reference.challenges && reference.challenges.map((challenge, index) => (
                    <li key={challenge} className={`leading-6 lg:leading-10 ${index > 0 && 'mt-8 lg:mt-0'}`}>{challenge}</li>
                  ))}
                </ul>
              </div>
              {reference.implementation.length > 0 && (
                <ScrollSlider
                  items={reference.implementation}
                  background={reference.implementation_bg}
                  itemHeadline
                />
              )}
              {testimonials && (
                <div className="my-24 lg:my-60">
                  <Testimonials data={testimonials} />
                </div>
              )}

              { reference.morereferences ? (
                <div className="px-4 py-12">
                  <References
                    title="Weitere Beispiele"
                    showMore={false}
                    filterLink={reference.morereferences}
                  />
                </div>
              ) : '' }

              {(!references || references.length === 0) && (
                <div className="my-24 lg:my-60" />
              )}

              {references && references.length > 0 && (
              <div className="my-24 lg:my-60">
                <FancyTitle
                  text="Ähnliche Referenzen"
                  className="text-left mb-3 lg:mb-5"
                  tag="h2"
                />
                <div className="grid gap-4 grid-cols-2 lg:grid-cols-4">
                  {references.map((edge) => {
                    const item = edge.frontmatter;
                    const image = getImage(item.image);
                    return (
                      <div className="relative overflow-hidden flex" key={item.title}>
                        {image && (
                        <GatsbyImage
                          image={image}
                          className="object-cover w-full h-48"
                          alt={item.title}
                        />
                        )}
                        <Link to={`/references/${item.link}`} className="absolute w-full h-full flex">
                          <div className="absolute w-full h-full bg-green-800 z-10 opacity-75 hover:bg-slate-700 hover:opacity-20 transition" />
                          <div className="m-auto z-20 pointer-events-none">
                            {
                              item.logo?.publicURL
                                ? (
                                  <img
                                    src={item.logo.publicURL}
                                    className="max-h-12 max-w-32"
                                    alt={item.title}
                                    loading="lazy"
                                  />
                                )
                                : (<h4>{item.title}</h4>)
                            }
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
              )}

            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default ReferenceTemplate;

export const referenceQuery = graphql`
  query ReferenceBySlug(
    $id: String!
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        meta_title
        meta_description
        description
        focal_point_x
        focal_point_y
        tags
        challenges
        sua_winner
        implementation {
          title
          description
          background_color
          image {
            name
            childImageSharp {
              gatsbyImageData(
                width: 2000
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        implementation_bg
        url
        link
        image {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        description_image {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        morereferences
        results {
          title
          description
          icon
        }
      }
    },
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(references)/"}}) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData(
                width: 480
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          link
          logo {
            publicURL
            extension
          }
        }
      }
    }
  }
`;
